<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-input
            v-model="filter.name"
            placeholder="用户名"
            @keyup.native.enter="getData"
          >
            <el-button
              slot="append"
              icon="el-icon-refresh"
              @click="handleRefresh"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-cascader
            placeholder="所属单位"
            @change="getData"
            v-model="filter.owner"
            :options="customerTree"
            :props="customerProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-cascader
            placeholder="区域"
            @change="getData"
            v-model="filter.area"
            :options="areaTree"
            :props="selAreaProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            type="daterange"
            v-model="PanicRangeTime"
            @change="getData"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' @click='getData'>查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="index" label="#" align="center" width="55" />
        <el-table-column
          prop="Name"
          label="用户"
          width="100"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Owner"
          :formatter="getPname"
          width="300"
          label="所属单位"
          show-overflow-tooltip
          header-align="center"
        />
        <!-- <el-table-column
          prop="AreaId"
          :formatter="getArea"
          show-overflow-tooltip
          label="所属地区"
          align="center"
          header-align="center"
        /> -->
        <el-table-column
          prop="IP"
          label="IP地址"
          width="120"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Time"
          label="最后一次登录时间"
          width="160"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="days"
          label="上线天数"
          width="80"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="type"
          label="用户类型"
          width="120"
          show-overflow-tooltip
          header-align="center"
        />
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>
  </section>
</template>

<script>
import { mapState } from 'vuex'
// import { dateFormater } from "@/util/index";
import {
  getSinglePname,
  getPname,
  ifNull,
  loadData,
  getUserInfo,
  join
} from '@/util/index'

export default {
  data() {
    return {
      loading: false,
      datalist: {},
      userInfo: {},
      org: null,
      filter: {
        val: null,
        owner: null,
        area: null,
        field: 'Name',
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      }
    }
  },
  computed: {
    ...mapState(['areaTree', 'clientHeight', 'locale', 'customerTree']),
    ...mapState({
      props: (state) => Object.assign({}, state.props, { label: 'Org' }),
      customerProps: (state) =>
        Object.assign({}, state.props, { label: 'Org', checkStrictly: true }),
      selAreaProps: (state) =>
        Object.assign({}, state.props, { checkStrictly: true })
    })
  },
  created() {
    try {
      const user = getUserInfo()
      this.userInfo = user.Info
    } catch (e) {
      this.$message.error(e)
    }
    this.getData()
  },
  methods: {
    getPname(r, c) {
      return getPname(this.customerTree, r[c.property], 'Org').join('/')
    },
    getArea(r, c) {
      return getSinglePname(this.areaTree, r[c.property])
    },
    getData: function() {
      this.loading = true
      this.datalist.content = this.locale
      if (this.filter.field && this.filter.val) {
        this.datalist.content = this.locale.filter(
          (v, _) => v[this.filter.field].indexOf(this.filter.val) !== -1
        )
      }
      if (this.filter.owner) {
        let owner = join(this.filter.owner)
        if (this.userInfo !== null) {
          owner = `${
            ifNull(this.userInfo['pid']) + this.userInfo['id']
          }${owner}`
        }
        this.datalist.content = this.datalist.content.filter((v, _) =>
          v.Owner.startsWith(owner)
        )
      }
      if (this.filter.area) {
        const area = join(this.filter.area)
        this.datalist.content = this.datalist.content.filter(
          (v, _) => v.AreaId.indexOf(area) === 0
        )
      }
      this.datalist.total = this.datalist.content.length
      this.$set(
        this.datalist,
        'content',
        this.datalist.content.slice(
          (this.filter.page - 1) * this.filter.size,
          this.filter.page * this.filter.size
        )
      )
      this.loading = false
    },
    handleRefresh() {
      this.filter.page = 1
      this.filter.val = null
      this.filter.owner = null
      this.filter.area = null
      loadData(
        this,
        (res) => {
          this.$store.dispatch('setLocale', res).then(() => {
            this.getData()
          })
        },
        3
      )
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select--mini ::v-deep .el-input--suffix {
  width: 120px;
}
</style>
